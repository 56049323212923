import { css } from "styled-components";

const breakpoints = {
  xxsmall: "360px",
  xsmall: "560px",
  small: "766px",
  medium: "1165px",
  large: "1300px"
};

const media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${breakpoints[label]}) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export default media;
