import React from "react";
import styled, { keyframes, css } from "styled-components";
import { Link } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";
import media from "./Media";

// components
import {
  Card,
  FlexContainer,
  H2,
  Text,
  TextLink
} from "@lewisblackwood/component-library";

// images
import PlayIcon from "../images/icon-play.svg";
import LinkedInIcon from "../images/icon-linkedin.svg";
import TwitterIcon from "../images/icon-twitter.svg";
import GithubIcon from "../images/icon-github.svg";

// Animations
const fadeInKeyframes = keyframes`
from { opacity: 0; }
to { opacity: 1; }
`;

const fadeInSlowKeyframes = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

export const fadeIn = props => css`
  animation: ${fadeInKeyframes} ${props.animationLength || "2s"};
`;

export const fadeInSlow = props => css`
  animation: ${fadeInSlowKeyframes} ${props.animationLength || "2s"};
`;

// Hero items

export const HeroStrapline = styled(Text)`
  font-size: 24px;
  margin-bottom: ${props => props.theme.spacing.large} !important;
  ${fadeIn}

  ${media.medium`
    font-size: 18px;
    margin-bottom: ${props => props.theme.spacing.small} !important;
  `};
`;

// Split 50/50 feature items

export const FeatureContainer = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing.xxlarge};
  position: relative;

  ${media.small`
    flex-direction: column;
    margin-bottom: ${props => props.theme.spacing.large};
  `};
`;

const FeatureContentStyling = css`
  width: 50%;
  display: inline-flex;
  vertical-align: middle;

  ${media.small`
    width: 100%;
  `};
`;

export const FeatureContentOne = styled.div`
  ${FeatureContentStyling};
  width: 450px;

  ${media.medium`
    width: 370px;
  `};

  ${media.small`
    width: 100%;
    order: 1;
  `};
`;

export const FeatureDescription = styled(FlexContainer)`
  ${media.small`
    text-align: center;
  `};
`;

export const FeatureContentTwo = styled.div`
  ${FeatureContentStyling};
  margin: ${props => props.theme.spacing.large} 0;
  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  ${media.small`
    justify-content: center;
    &:nth-child(even) {
      flex-direction: column;
    }
  `};
`;

// Shapes

const ShapeBackground = css`
  background-color: ${props => props.theme.colours[props.colour].lightest};
  width: ${props => props.width || "450px"};
  height: ${props => props.width || "450px"};
  margin: 0;
  padding: ${props => props.theme.spacing.medium};
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.medium`
    width: ${props => props.width || "360px"};
    height: ${props => props.width || "360px"};
  `};

  ${media.small`
    width: ${props => props.width || "340px"};
    height: ${props => props.width || "340px"};
  `};
`;

export const CircleBackground = styled.div`
  ${ShapeBackground};
  border-radius: 100%;
`;

export const TriangleBackground = styled.div`
  ${ShapeBackground};
  clip-path: polygon(0% 100%, 100% 100%, 0% 0%);
`;

export const TriangleUpsideDown = styled.div`
  ${ShapeBackground};
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
`;

//Icon

export const Icon = styled.span`
  background-image: ${props => `url(${props.image})`};
  background-size: cover;
  display: inline-block;
  margin-bottom: ${props => `-${props.theme.spacing.xxxsmall}`};
  height: ${props =>
    props.size ? props.theme.spacing[props.size] : props.theme.spacing.medium};
  width: ${props =>
    props.size ? props.theme.spacing[props.size] : props.theme.spacing.medium};
`;

export const Avatar = styled.div`
  background: url(${props => props.image});
  background-size: cover;
  border-radius: 100%;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  grid-column: ${props => props.column};
  grid-row: ${props => props.row};
  height: 96px;
  justify-self: center;
  overflow: hidden;
  position: relative;
  width: 96px;
  z-index: 1;
`;

const StyledProfileCard = styled(ScrollAnimation).attrs({
  animateOnce: true,
  animateIn: "fadeAndRiseUp"
})`
  display: grid;
  grid-template-rows: ${props =>
    `48px 48px ${props.theme.spacing.medium}
    auto ${props.theme.spacing.medium}`};
  grid-template-columns: 20px 1fr auto 1fr 20px;
  margin: 10px;
  max-width: 460px;
  min-height: 370px;
  min-width: 340px;
  width: calc(33% - 16.5px);

  p:nth-child(3) {
    min-height: ${props => (props.team ? "140px" : "")};
  }

  ${media.medium`
  min-height: auto;

  p:nth-child(3) {
    min-height: ${props => (props.team ? "190px" : "")};
  }

  `}

  ${media.small`
  width: 100%;
  min-width: 200px;

  p:nth-child(3) {
    min-height: ${props => (props.team ? "auto" : "")};
  }
  `}
`;

export const ProfileCard = props => (
  <StyledProfileCard team={props.team}>
    <Avatar image={props.image} row="1 / 2" column="3" />
    <Card
      css={{
        gridRow: "2 / 6",
        gridColumn: "1 / 6",
        justifyContent: "flex-start"
      }}
    >
      <Text
        size="xlarge"
        colour="black"
        bold
        center
        mb="xxxsmall"
        css={{ marginTop: 48 }}
      >
        {props.name}
      </Text>
      <Text size="large" center mb="xsmall" bold>
        {props.company ? `${props.role}, ${props.company}` : `${props.role}`}
      </Text>
      <Text size="large" center mb="medium">
        {props.info}
      </Text>
      {props.caseStudy ? (
        <Text center>
          <Link to={props.caseStudy}>
            <TextLink colour="purple" bold>
              {props.caseStudy ? `Read ${props.company}’s story` : ""}
            </TextLink>
          </Link>
        </Text>
      ) : null}

      {props.github || props.twitter || props.linkedin ? (
        <FlexContainer style={{ alignSelf: "center" }}>
          {props.github ? (
            <a href={props.github} target="_blank">
              <Icon image={GithubIcon} aria-label={`${props.name}'s Github`} />
            </a>
          ) : null}
          {props.twitter ? (
            <a href={props.twitter} target="_blank">
              <Icon
                image={TwitterIcon}
                aria-label={`${props.name}'s Twitter`}
              />
            </a>
          ) : null}
          {props.linkedin ? (
            <a href={props.linkedin} target="_blank" style={{ marginLeft: 12 }}>
              <Icon
                image={LinkedInIcon}
                aria-label={`${props.name}'s LinkedIn`}
              />
            </a>
          ) : null}
        </FlexContainer>
      ) : null}
    </Card>
  </StyledProfileCard>
);

export const FeatureCard = styled(StyledProfileCard)`
  min-height: 300px;

  ${media.medium`
    min-height: 300px;
  `}

  ${media.small`
    min-height: auto;
  `}
`;

export const ProfilePhoto = styled.div`
  position: absolute;
  top: -48px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  height: 96px;
  width: 96px;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.1);
  background: url(${props => props.profileImage});
  background-size: cover;
`;

const StyledContentCard = styled(ScrollAnimation).attrs({
  animateOnce: true,
  animateIn: "fadeAndRiseUp"
})`
  margin: 10px;
  max-width: 460px;
  min-height: 300px;
  min-width: 340px;
  width: calc(33% - 16.5px);

  > div {
    width: 100%;
    min-height: 300px;
    justify-content: start;
  }

  ${media.medium`
  min-height: auto;
  `}

  ${media.small`
  width: 100%;
  min-width: 200px;
  `}
`;

export const ContentCard = props => (
  <StyledContentCard>
    <Card>
      {props.children}
      <Text
        size="xlarge"
        colour={props.titleColour || "black"}
        bold
        center
        mb="xsmall"
      >
        {props.title}
      </Text>
      <Text size="xlarge" sizeMobile="large" center mb="xsmall">
        {props.text}
      </Text>
      <Text
        center
        mb="none"
        style={{ position: "absolute", bottom: 20, alignSelf: "center" }}
      >
        <TextLink
          as="a"
          colour={props.refColour || "black"}
          bold
          href={props.refLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.reference}
        </TextLink>
      </Text>
    </Card>
  </StyledContentCard>
);

// Illustration Icons

export const Illustration = styled.img.attrs(props => ({
  src: props.src,
  alt: ""
}))`
  height: 80px;
  margin-bottom: ${props => (props.mb ? props.theme.spacing[props.mb] : "")};
  grid-row: ${props => props.row};
  grid-column: ${props => props.column};
  width: auto;
  z-index: 1;
`;

// Curved sections

export const Curve = styled.img.attrs(props => ({
  src:
    props.colour === "purple"
      ? CurvePurple
      : props.colour === "green"
      ? CurveGreen
      : props.colour === "pink"
      ? CurvePink
      : CurveGrey,
  alt: ""
}))`
  width: 100%;
  height: 48px;
  vertical-align: top;
  grid-row: ${props => props.gridRow};
  grid-column: ${props => props.gridCol};

  ${props => {
    if (props.rotate180) {
      return `transform: rotate(180deg);`;
    } else {
      return `margin-bottom: 5px;`;
    }
  }};

  ${media.medium`
    height: 24px;
  `};
`;

export const MiddleSection = styled.div`
  text-align: ${props => props.align || "center"};
  position: relative;
  background-color: ${props =>
    props.theme.colours[props.colour]
      ? props.theme.colours[props.colour].lightest
      : "#FCFCFC"};
  margin-top: -5px;
  padding: ${props =>
    `${props.theme.spacing.xlarge} ${props.theme.spacing.large}`};

  ${media.medium`
    text-align: center;
    padding: ${props =>
      `${props.theme.spacing.xlarge} ${props.theme.spacing.medium}`};
  `};

  ${media.small`
    text-align: center;
    padding: ${props =>
      `${props.theme.spacing.large} ${props.theme.spacing.small}`};
  `};
`;

// Product video

export const PlayButton = styled.button`
  align-items: baseline;
  background: transparent;
  border: none;
  display: flex;
  margin-top: ${props =>
    props.mt ? props.theme.spacing[props.mt] : props.theme.spacing.large};
  padding: ${props => props.theme.spacing.xsmall};

  div {
    background-color: ${props => props.theme.colours.purple.lightest};
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px 1px;
    height: 28px;
    margin-right: 12px;
    width: 28px;
    transition: 0.8s cubic-bezier(0.2, 0.82, 0.2, 1);
  }

  img {
    margin: 7px 0 0 2px;
  }

  :hover div {
    background-color: ${props => props.theme.colours.purple.light};
  }

  span:hover {
    cursor: default;
    text-decoration: none;
  }

  :active {
    outline: none;
  }

  ${media.medium`
    margin-top: 0;
  `};
`;

const StyledLargePlayButton = styled.button.attrs({
  "aria-label": "Watch product video"
})`
  align-content: center;
  background-color: ${props => props.theme.colours.purple.lightest};
  border: none;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px 1px;
  cursor: pointer;
  display: flex;
  grid-column: ${props => props.column || ""};
  grid-row: ${props => props.row || ""};
  height: 100%;
  justify-content: center;
  padding: 0;
  transition: 0.8s cubic-bezier(0.2, 0.82, 0.2, 1);
  width: 100%;
  z-index: 1;

  &:hover {
    background-color: ${props => props.theme.colours.purple.light};
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px 1px;
    transform: translateY(-2px);
  }

  &:active {
    outline: none;
  }

  > img {
    transform: translateX(2px);
    width: 30%;
  }
`;

export const LargePlayButton = props => (
  <StyledLargePlayButton column={props.column} row={props.row}>
    <img src={PlayIcon} />
  </StyledLargePlayButton>
);

export const ProductVideo = styled.iframe.attrs({
  src: "https://www.youtube.com/embed/ElzKdCmyYT8?autoplay=1&showinfo=0&rel=0",
  frameBorder: 0,
  allow:
    "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
  allowFullScreen: "allowfullscreen",
  mozallowFullScreen: "mozallowfullscreen",
  msallowFullScreen: "msallowfullscreen",
  oallowFullScreen: "oallowfullscreen",
  webkitAllowFullScreen: "webkitallowfullscreen"
})`
  border: 0;
  height: 405px;
  width: 720px;

  ${media.small`
      height: 144px;
      width: 256px;
  `};
`;

// Misc

export const WrapFlexItemContainer = styled(ScrollAnimation).attrs({
  animateOnce: true
})`
  position: relative;
  width: 270px;
  margin: ${props =>
    props.theme.spacing[props.margin] || props.theme.spacing.medium};

  ${media.small`
    width: 100%;
    margin: ${props =>
      props.theme.spacing[props.margin] || `${props.theme.spacing.medium} 0`};
  `};
`;
