import React from "react";
import { Card } from "@lewisblackwood/component-library";

const ShutdownNotice = () => (
  <Card
    style={{
      backgroundColor: "#F5F5F5",
      display: "block",
      margin: "0 auto",
      textAlign: "center"
    }}
    width={"fit-content"}
  >
    Sadly, Personably is shutting down. You can read more{" "}
    <a
      href="https://www.personably.co/blog/personably-is-shutting-down"
      rel="noopener noreferrer"
      target="_blank"
    >
      here
    </a>
    .
  </Card>
);

export default ShutdownNotice;
