import styled from "styled-components";
import media from "./Media";
import { PersonablyLogo, PersonablyIcon } from "@lewisblackwood/component-library";

export const Header = styled.header`
  padding: ${props =>
    `${props.theme.spacing.small} ${props.theme.spacing.large}`};
  max-width: 1440px;
  margin: 0 auto;

  ${media.small`
    padding: ${props => props.theme.spacing.small};
  `};
`;

export const NavMenuContainer = styled.ul`
  display: none;
  list-style: none;
  float: right;

  ${media.small`
    display: block;
  `};
`;

export const NavContainer = styled.ul`
  float: right;
  list-style: none;
  margin: 0;

  ${media.small`
    background-color: ${props => props.theme.colours.white.default};
    display: ${props => (props.open ? "block" : "none")};
    float: none;
    margin-bottom: ${props => props.theme.spacing.small};
    padding: ${props => props.theme.spacing.medium};
    transform: translateX(-16px);
    width: 100vw;

    > li {
      margin-top: ${props => props.theme.spacing.small};
      width: 100%;
    }

    > li a {
      width: auto;
    }
  `};
`;

export const NavItem = styled.li`
  display: inline-block;
  margin-right: ${props => props.theme.spacing.large};
  margin-bottom: ${props => props.theme.spacing.xsmall};
  width: ${props => props.width};

  &:last-child {
    margin-right: 0;
  }
`;

export const NavButton = styled.button`
  background: none;
  border: none;
  padding: ${props => `${props.theme.spacing.small} 0`};

  ${media.small`
    padding: 0;
  `};
`;

export const NavDropdownContainer = styled.div`
  display: ${props => (props.open ? "block" : "none")};
  padding-top: ${props => props.theme.spacing.xxsmall};
  position: absolute;
  transform: ${props => `translateX(-${props.theme.spacing.xlarge})`};
  z-index: 1;

  ${media.small`
    position: relative;
    transform: none;
    z-index: 0;
  `};
`;

export const NavDropdown = styled.ul`
  background: ${props => props.theme.colours.white.default};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px;
  padding: ${props => props.theme.spacing.small};
  width: 240px;
  z-index: 1;

  > li {
    margin: ${props => `${props.theme.spacing.xxxsmall} 0`};
  }

  > li > a {
    padding: ${props => props.theme.spacing.xxsmall};
    display: block;
  }

  > li > a:hover {
    background-color: hsl(0, 0%, 98%);
    border-radius: 4px;
  }

  ${media.small`
    background: none;
    box-shadow: none;
    margin: ${props => `0 0 0 ${props.theme.spacing.xsmall}`};
    padding: 0;
    width: calc(100vw - 72px);

    > li {
      margin-top: ${props => props.theme.spacing.xsmall};
    }

    > li:last-child {
      margin-bottom: 0;
    }

  `};
`;

export const NavDropdownArrow = styled.div`
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.075) -1px -1px 1px;
  content: "";
  display: block;
  position: absolute;
  background-color: ${props => props.theme.colours.white.default};
  height: ${props => props.theme.spacing.medium};
  left: 110px;
  top: 0;
  transform: rotateZ(45deg);
  width: ${props => props.theme.spacing.medium};

  ${media.small`
    display: none;
  `};
`;

export const NavDropdownHide = styled.div`
  position: absolute;
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  ${media.small`
    display: none;
  `};
`;

export const Logo = styled.div`
  background: url(${PersonablyLogo}) no-repeat;
  background-size: cover;
  display: inline-block;
  position: relative;
  top: 4px;
  height: 38px;
  width: 156px;

  ${media.small`
    width: 40px;
    height: 55px;
    top: 0;
    background: url(${PersonablyIcon}) no-repeat;
    background-size: cover;
  `};
`;
