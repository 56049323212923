import React from "react";
import PropTypes from "prop-types";

import Helmet from "react-helmet";
import ScrollAnimation from "react-animate-on-scroll";
import styled, { css, ThemeProvider, withTheme } from "styled-components";
import { Theme, GlobalStyle } from "@lewisblackwood/component-library";

// components
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import ShutdownNotice from "../components/ShutdownNotice";
import Favicon from "../images/favicon.ico";
import media from "../styledComponents/Media";
import "../style.css";

export const PageContainer = styled.main`
  margin-top: ${props => props.theme.spacing.xlarge};
  grid-template-rows: 1fr;
  display: grid;
  grid-template-columns:
    minmax(32px, 1fr)
    minmax(auto, 1400px)
    minmax(32px, 1fr);
  width: 100vw;

  ${media.medium`
    grid-template-columns: 20px calc(100vw - 40px) 20px;
    margin-top: ${props => props.theme.spacing.xlarge};
  `};

  ${media.small`
    grid-template-columns: 16px calc(100vw - 32px) 16px;
    margin-top: ${props => props.theme.spacing.large};
  `};
`;

const GridPosition = css`
  grid-column: ${props => props.column || "2"};
  grid-row: ${props => props.row};
`;

// For simple layouts
export const ContentSection = styled.div`
  ${GridPosition};

  margin-bottom: ${props =>
    props.colour
      ? "0px"
      : props.mb
      ? props.theme.spacing[props.mb]
      : props.theme.spacing.xxlarge};
  background-color: ${props =>
    props.colour ? props.theme.colours[props.colour].lightest : ""};

  ${media.small`
    margin-bottom: ${props =>
      props.colour
        ? "0px"
        : props.mb
        ? props.theme.spacing[props.mb]
        : props.theme.spacing.xlarge};
  `};
`;

ContentSection.propTypes = {
  colour: PropTypes.oneOf(["purple", "green", "pink", "grey"]),
  column: PropTypes.string,
  mb: PropTypes.oneOf([
    "none",
    "xxsmall",
    "xsmall",
    "small",
    "medium",
    "large",
    "xlarge",
    "xxlarge"
  ]),
  row: PropTypes.string
};

ContentSection.defaultProps = {
  column: "2"
};

// For image-text two column layouts
export const ImageTextSection = styled(ContentSection)`
  display: grid;
  grid-column-gap: ${props => props.theme.spacing.medium};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  ${media.medium` {
    grid-column: ${props => props.columnMobile};
  `}

  ${media.small`
    grid-column-gap: 0;
    grid-row-gap: ${props => `${props.theme.spacing.medium}`};
    grid-template-columns: 1fr;
    grid-template-rows: [image] auto [text] auto;
  `};
`;

ImageTextSection.propTypes = {
  colour: PropTypes.oneOf(["purple", "green", "pink", "grey"]),
  column: PropTypes.string,
  row: PropTypes.string
};

// For image part of image-text layout

export const ImageSection = styled.div.attrs(props => ({
  wrap: props.wrap ? 1 : 0
}))`
  align-items: center;
  display: flex;
  flex-wrap: ${props => (props.wrap ? "wrap" : "")};
  grid-column: ${props => props.column || "auto"};
  grid-row: ${props => props.row};
  margin-bottom: ${props => (props.mb ? props.theme.spacing[props.mb] : "")};
  justify-content: center;

  ${media.small`
    grid-column: ${props => props.columnMobile || "auto"};
    grid-row: ${props => props.row || "1"};
  `};
`;

ImageSection.propTypes = {
  column: PropTypes.string,
  row: PropTypes.string,
  wrap: PropTypes.bool
};

// For text part of image-text layout
export const TextSection = styled.div`
  align-items: ${props => (props.quote ? "center" : "start")};
  display: flex;
  flex-direction: column;
  grid-column: ${props => props.column || "auto"};
  grid-row: ${props => props.row || ""};
  justify-content: center;
  max-width: ${props =>
    props.hero ? "520px" : props.quote ? "660px" : "440px"};
  margin: 0 auto;

  > ul {
    padding-left: ${props => props.theme.spacing.small};
  }

  > ul > li {
    padding-left: ${props => props.theme.spacing.xxxsmall};
  }

  > p,
  h1,
  h2,
  h3 {
    width: 100%;
  }

  ${media.small`
    grid-column: auto;
    grid-row: 2;
    margin: ${props => (props.quote ? "16px 16px 0 16px" : "")};
    min-width: calc(50vw - 30px);
    text-align: center;

    > a {
      width: 100%;
    }

    > ul {
      list-style: none;
    }

    > ul > li {
      margin-top: ${props => props.theme.spacing.xxsmall};
    }

  `};
`;

TextSection.propTypes = {
  column: PropTypes.string,
  row: PropTypes.string,
  hero: PropTypes.bool,
  quote: PropTypes.bool
};

export const Title = styled(TextSection)`
  align-items: center;
  max-width: 660px;

  ${media.small`
  grid-row: ${props => props.row || ""};
  `};
`;

// For image part of image-text layout with multiple divs, svgs, etc.
export const GridImageSection = styled.div`
  display: grid;
  grid-column: ${props => props.column};
  grid-template-columns: ${props => props.columns};
  grid-template-rows: ${props => props.rows};
  margin: 0 auto;
  max-width: calc(50vw - 52px);

  ${media.small` {
    max-width: calc(100vw - 32px);
    grid-column: auto;
    grid-row: 1;
  `}
`;

GridImageSection.propTypes = {
  columns: PropTypes.string,
  rows: PropTypes.string
};

//Circle
const CircleContainer = styled.div`
  ${GridPosition};
  align-self: center;
  grid-column: ${props => props.column || ""};
  height: 44vw;
  justify-self: center;
  max-height: ${props => (props.small ? "360px" : "480px")};
  max-width: ${props => (props.small ? "360px" : "480px")};
  width: 44vw;
  z-index: -1;

  ${media.small`
    height: 80vw;
    width: 80vw;
    margin-bottom: ${props => props.theme.spacing.medium};
    `}
`;

const StyledCircle = styled.div`
  background-color: ${props => props.theme.colours[props.colour].lightest};
  border-radius: 100%;
  height: 100%;
  max-height: 480px;
  max-width: 480px;
  width: 100%;
`;

export const CircleBackground = props => (
  <CircleContainer column={props.column} row={props.row} small={props.small}>
    <StyledCircle colour={props.colour} />
  </CircleContainer>
);

CircleBackground.propTypes = {
  colour: PropTypes.oneOf(["purple", "green", "pink", "grey"]),
  column: PropTypes.string,
  row: PropTypes.string
};

CircleBackground.defaultProps = {
  colour: "grey"
};

// For sections with overlapping content, e.g. putting overlapping an image over the curve component
export const GridSection = styled(ContentSection)`
  display: grid;
  grid-template-columns: ${props =>
    props.itemWidthLarge
      ? `repeat(auto-fill, minmax(${props.itemWidthLarge}, 1fr))`
      : props.columns || ""};
  grid-template-rows: ${props => props.rows};
  grid-gap: ${props => (props.gap ? props.theme.spacing.medium : "")};
  width: ${props => (props.fullWidth ? "100vw" : "100%")};

  ${media.large`
    grid-template-columns: ${props =>
      props.itemWidth
        ? `repeat(auto-fill, minmax(${props.itemWidth}, 1fr))`
        : ""};
  `};

  ${media.medium`
    width: ${props =>
      props.fullWidth ? "" : `calc(100vw - ${props.theme.spacing.xlarge})`};
  `};

  ${media.small`
    grid-template-columns: ${props =>
      props.itemWidthSmall
        ? `repeat(auto-fill, minmax(${props.itemWidthSmall}, 1fr))`
        : props.columnsMobile || ""};
    width: ${props => (props.fullWidth ? "" : `calc(100vw - 32px)`)};
  `};
`;

GridSection.propTypes = {
  colour: PropTypes.oneOf(["purple", "green", "pink", "grey"]),
  column: PropTypes.string,
  columns: PropTypes.string,
  mb: PropTypes.oneOf([
    "none",
    "xxsmall",
    "xsmall",
    "small",
    "medium",
    "large",
    "xlarge",
    "xxlarge"
  ]),
  row: PropTypes.string,
  rows: PropTypes.string,
  fullWidth: PropTypes.bool,
  gap: PropTypes.bool,
  itemWidth: PropTypes.string,
  itemWidthLarge: PropTypes.string,
  itemWidthSmall: PropTypes.string
};

export const FlexSection = styled(ContentSection)`
  align-items: ${props => props.alignItems || "center"};
  display: flex;
  flex-direction: ${props => props.flexDirection || "column"};
  flex-wrap: ${props => (props.wrap ? "wrap" : "")};
  justify-content: ${props => props.justifyContent};
  justify-self: ${props => props.justifySelf};

  ${media.small`
    > div > a , button {
      width: 100%;
    }
  `};
`;

FlexSection.propTypes = {
  alignItems: PropTypes.string,
  colour: PropTypes.oneOf(["purple", "green", "pink", "grey"]),
  column: PropTypes.string,
  flexDirection: PropTypes.string,
  mb: PropTypes.oneOf([
    "none",
    "xxsmall",
    "xsmall",
    "small",
    "medium",
    "large",
    "xlarge",
    "xxlarge"
  ]),
  row: PropTypes.string,
  wrap: PropTypes.bool
};

const StyledCurve = styled.div`
  ${GridPosition};
  align-self: ${props => props.alignSelf};
  display: grid;
  grid-template-columns: [start] 160px [middle] auto [end] 160px;
  grid-template-rows: 1fr;
  margin-bottom: ${props => (props.mb ? props.theme.spacing[props.mb] : "")};
  transform: ${props => (props.flipped ? "rotateZ(180deg)" : "")};
  z-index: -1;

  ${media.small`
    grid-template-columns: [start] 80px [middle] auto [end] 80px;
  `};
`;

const CurveBlockContainer = styled.div`
  grid-area: ${props => props.area};
  display: block;
  height: 160px;

  ${media.small`
    height: 80px;
    width: 80px;
  `};
`;

const CurveMiddleBlock = styled.div`
  background: ${props => props.theme.colours[props.colour].lightest};
  grid-area: middle;
  height: 80px;
  margin-top: 80px;
  width: 100%;

  ${media.small`
    height: 40px;
    margin-top: 40px;
  `};
`;

const CurveBlock = props =>
  props.svg ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
      {" "}
      <path
        fill={props.theme.colours[props.colour].lightest}
        d={
          props.svg === "start"
            ? "M120,120 L40,120 L40,40 C40,62.09139 57.90861,80 80,80 L120,80 L120,120 Z"
            : "M480,80 C502.09139,80 520,97.90861 520,120 L440,120 L440,80 L480,80 Z"
        }
        transform={
          props.svg === "start" ? "translate(-40 -40)" : "translate(-440 -40)"
        }
      />{" "}
    </svg>
  ) : null;

const CurveWithTheme = props => (
  <StyledCurve
    column={props.column}
    row={props.row}
    alignSelf={props.alignSelf}
    mb={props.mb}
    flipped={props.flipped}
  >
    <CurveBlockContainer area="start" flipped={props.flipped}>
      <CurveBlock colour={props.colour} theme={props.theme} svg="start" />
    </CurveBlockContainer>
    <CurveMiddleBlock colour={props.colour} />
    <CurveBlockContainer area="end" flipped={props.flipped}>
      <CurveBlock colour={props.colour} theme={props.theme} svg="end" />
    </CurveBlockContainer>
  </StyledCurve>
);

export const Curve = withTheme(CurveWithTheme);

Curve.propTypes = {
  alignSelf: PropTypes.string,
  column: PropTypes.string,
  colour: PropTypes.oneOf(["purple", "green", "pink", "grey"]),
  flipped: PropTypes.bool,
  mb: PropTypes.oneOf([
    "none",
    "xxsmall",
    "xsmall",
    "small",
    "medium",
    "large",
    "xlarge",
    "xxlarge"
  ]),
  row: PropTypes.string
};

Curve.defaultProps = {
  colour: "grey",
  column: "1 / 4"
};

// For adding colour into margins to get full-width effect
export const FillMargin = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colours[props.colour].lightest};
  grid-column: ${props => (props.right ? "3" : props.column || "1")};
  grid-row: ${props => props.row};
`;

FillMargin.propTypes = {
  colour: PropTypes.oneOf(["purple", "green", "pink", "grey"]),
  right: PropTypes.bool
};

FillMargin.defaultProps = {
  colour: "grey"
};

export const FlexItem = styled(ScrollAnimation).attrs({
  animateIn: "fadeAndRiseUp",
  animateOnce: true
})`
  max-width: 460px;
  min-width: 340px;
  padding: 10px;
  width: calc(33% - 16.5px);

  ${media.small`
    max-width: calc(100vx - 32px);
    min-width: auto;
    padding: 8px;
    width: 100%;
  `}
`;

// Layout
export const Layout = props => (
  <ThemeProvider theme={Theme}>
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <Helmet
        defaultTitle={`Personably | Employee onboarding`}
        titleTemplate={`Personably | %s`}
      >
        <link rel="icon" href={Favicon} sizes="192x192" type="image/png" />
        <link rel="apple-touch-icon" href={Favicon} />
        <link rel="apple-touch-icon" sizes="72x72" href={Favicon} />
      </Helmet>
      <GlobalStyle />
      <NavBar />
      <ShutdownNotice />
      <PageContainer>{props.children}</PageContainer>
      <Footer />
    </div>
  </ThemeProvider>
);

export default Layout;
