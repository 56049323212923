import React from "react";
import styled from "styled-components";
import media from "../Media";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import { fadeIn } from "../Common";

const StyledImage = styled.div`
  display: block;
  grid-column: ${props => props.column};
  grid-row: ${props => props.row};
  height: auto;
  overflow: ${props => (props.cropped ? "hidden" : "")};
  width: 100%;
  ${fadeIn};

  img {
    border-radius: ${props => (props.rounded ? "8px" : "")};
    box-shadow: ${props =>
      props.shadow ? "rgba(0, 0, 0, 0.1) 0px 1px 4px 1px" : ""};
    display: block;
    margin: 0 auto;
    max-height: 72vw;
    max-width: fit-content;
    width: ${props => (props.fit ? "100%" : "auto")};
  }

  ${media.medium`
    max-width: calc(50vw - 52px);
    
    img {
    width: ${props =>
      props.fitMobile ? "calc(50vw - 52px)" : props.fit ? "100%" : "auto"};
    }
  `};

  ${media.small`
    max-width: calc(100vw - 32px);
    
    img {
    width: ${props =>
      props.fitMobile ? "calc(100vw - 32px)" : props.fit ? "100%" : "auto"};
    }
  `};
`;

export const Image = props => (
  <StyledImage
    column={props.column}
    row={props.row}
    cropped={props.cropped}
    fit={props.fit}
    fitMobile={props.fitMobile}
    style={props.style}
    rounded={props.rounded}
    shadow={props.shadow}
    animateIn={props.animateIn}
  >
    {props.children}
    <img src={props.src} alt={props.alt || ""} />
  </StyledImage>
);

Image.propTypes = {
  column: PropTypes.string,
  row: PropTypes.string,
  cropped: PropTypes.bool,
  fit: PropTypes.bool,
  fitMobile: PropTypes.bool,
  style: PropTypes.string,
  rounded: PropTypes.bool,
  shadow: PropTypes.bool
};

const StyledImageAnimated = styled(ScrollAnimation).attrs({
  animateOnce: true,
  animateIn: "fadeAndRiseUp"
})`
  display: flex;
`;

export const ImageAnimated = props => (
  <StyledImage {...props}>
    <StyledImageAnimated>
      <img src={props.src} alt={props.alt || ""} />
    </StyledImageAnimated>
  </StyledImage>
);

export const Fade = styled.div`
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
  height: 288px;
  right: ${props => props.theme.spacing.small};
  position: absolute;
  width: 40px;
  z-index: 1;

  ${media.medium`
  right: 0;
  `};

  ${media.small`
  right: ${props => props.theme.spacing.small};
  `};
`;
