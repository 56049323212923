import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { NavItem } from "./NavBar";
import { PageContainer } from "../components/layout";
import { Text, TextLink, StyledLink } from "@lewisblackwood/component-library";
import media from "./Media";

export const FooterContainer = props => (
  <PageContainer as="footer" style={{ marginTop: 0 }}>
    {props.children}
  </PageContainer>
);

export const FooterTextSection = styled.div`
  max-width: calc(50% - 10px);

  ${media.medium`
    max-width: calc(100% - 100px);
  `};

  ${media.small`
    max-width: 100%;
  `};
`;

export const FooterNavContainer = styled.ul`
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-right: 80px;
  max-width: calc(50% - 10px);

  ${media.medium`
    float: none;
    max-width: calc(100% - 100px);
  `};

  ${media.small`
    max-width: 100%;
    margin-right: 0;
  `};
`;

export const StyledFooterNavItem = styled(NavItem)`
  p,
  a,
  span {
    font-size: 16px;
    font-weight: bold;
  }
  margin-right: ${props => props.theme.spacing.medium};

  &:nth-last-child(2) {
    margin-right: 0;
  }

  ${media.small`
    margin-right: ${props => props.theme.spacing.medium};
  `};
`;

const FooterNavItemContent = props => {
  if (props.to) {
    return (
      <Link to={props.to}>
        <TextLink noHover size="small" colour="black">
          {props.children}
        </TextLink>
      </Link>
    );
  } else if (props.href) {
    return (
      <StyledLink noHover size="small" colour="black" href={props.href}>
        {props.icon ? (
          <img
            src={props.icon}
            height={16}
            alt={props.children}
            style={{ position: "relative", top: 1 }}
          />
        ) : (
          `${props.children}`
        )}
      </StyledLink>
    );
  } else {
    return null;
  }
};

export const FooterNavItem = props => (
  <StyledFooterNavItem>{FooterNavItemContent(props)}</StyledFooterNavItem>
);

export const MadeIn = styled(Text)`
  text-align: right;

  ${media.small`
    text-align: left;
  `};
`;
