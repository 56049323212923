import React from "react";
import { Link } from "gatsby";

// styled-components
import { LinkButton, TextLink } from "@lewisblackwood/component-library";
import {
  Header,
  NavContainer,
  NavItem,
  Logo,
  NavButton,
  NavDropdown,
  NavMenuContainer,
  NavDropdownArrow,
  NavDropdownContainer,
  NavDropdownHide
} from "../styledComponents/NavBar";
import { Icon } from "../styledComponents/Common";

// images
import IconChevronDown from "../images/icon-chevron-down.svg";

class NavBar extends React.Component {
  state = { HowItWorksIsOpen: false, MenuIsOpen: false };

  toggleHowItWorksIsOpen = () => {
    this.setState({ HowItWorksIsOpen: !this.state.HowItWorksIsOpen });
  };

  toggleMenuIsOpen = () => {
    this.setState({
      MenuIsOpen: !this.state.MenuIsOpen
    });
  };

  render() {
    const activeStyle = {
      borderBottom: "2px solid #9358D6",
      paddingBottom: "12px"
    };

    const backgroundFill = {
      backgroundColor: "#F5F5F5",
      borderRadius: 4,
      display: "block",
      width: "100%"
    };

    return (
      <Header>
        <Link to="/">
          <Logo role="img" aria-label="Personably" />
        </Link>
        <NavMenuContainer>
          <NavItem>
            <NavButton
              onClick={this.toggleMenuIsOpen}
              aria-expanded={this.state.MenuIsOpen ? "true" : "false"}
            >
              <TextLink noHover bold size="large" colour="black">
                Menu <Icon image={IconChevronDown} />
              </TextLink>
            </NavButton>
          </NavItem>
        </NavMenuContainer>

        <NavContainer open={this.state.MenuIsOpen}>
          <NavItem>
            <NavButton
              onClick={this.toggleHowItWorksIsOpen}
              aria-expanded={this.state.HowItWorksIsOpen ? "true" : "false"}
            >
              <TextLink noHover bold size="large" colour="black">
                How it works <Icon image={IconChevronDown} />
              </TextLink>
            </NavButton>
            <NavDropdownContainer open={this.state.HowItWorksIsOpen}>
              <NavDropdown>
                <NavDropdownArrow />
                <NavItem width="100%">
                  <Link to="/product/tasks" activeStyle={backgroundFill}>
                    <TextLink noHover bold size="large" colour="black">
                      Tasks
                    </TextLink>
                  </Link>
                </NavItem>
                <NavItem width="100%">
                  <Link to="/product/scheduling" activeStyle={backgroundFill}>
                    <TextLink noHover bold size="large" colour="black">
                      Scheduling
                    </TextLink>
                  </Link>
                </NavItem>
                <NavItem width="100%">
                  <Link
                    to="/product/welcome-dashboard"
                    activeStyle={backgroundFill}
                  >
                    <TextLink noHover bold size="large" colour="black">
                      Welcome Dashboard
                    </TextLink>
                  </Link>
                </NavItem>
              </NavDropdown>
            </NavDropdownContainer>
            {this.state.HowItWorksIsOpen ? (
              <NavDropdownHide
                onClick={() => this.setState({ HowItWorksIsOpen: false })}
              />
            ) : null}
          </NavItem>

          <NavItem>
            <Link to="/pricing" activeStyle={activeStyle}>
              <TextLink noHover bold size="large" colour="black">
                Pricing
              </TextLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/about" activeStyle={activeStyle}>
              <TextLink noHover bold size="large" colour="black">
                About us
              </TextLink>
            </Link>
          </NavItem>
          <NavItem>
            <Link to="/blog" activeStyle={activeStyle}>
              <TextLink noHover bold size="large" colour="black">
                Blog
              </TextLink>
            </Link>
          </NavItem>
        </NavContainer>
      </Header>
    );
  }
}

export default NavBar;
