import React from "react";

// components
import { Text } from "@lewisblackwood/component-library";
import { Curve, FillMargin, FlexSection } from "../components/layout";
import {
  FooterContainer,
  FooterTextSection,
  FooterNavContainer,
  FooterNavItem,
  MadeIn
} from "../styledComponents/Footer.js";

// images
import TwitterIcon from "../images/icon-twitter.svg";
import LinkedInIcon from "../images/icon-linkedin.svg";

const Footer = () => (
  <FooterContainer>
    <Curve />
    <FillMargin />
    <FlexSection
      colour="grey"
      flexDirection="row"
      alignItems="first-baseline"
      justifyContent="space-between"
      wrap
      style={{ paddingBottom: 40 }}
    >
      <FooterTextSection>
        <Text size="small" mb="small">
          Personably is a trading name of Coro Ltd. Company number: 10750352
        </Text>
        <Text size="small" mb="medium">
          Registered address: Kemp House, 160 City Road, London,
          United&nbsp;Kingdom, EC1V&nbsp;2NX
        </Text>
      </FooterTextSection>

      <FooterNavContainer>
        <FooterNavItem to="/pricing">Pricing</FooterNavItem>
        <FooterNavItem to="/about">About us</FooterNavItem>
        <FooterNavItem to="/blog">Blog</FooterNavItem>
        <FooterNavItem to="/terms">Terms</FooterNavItem>
        <FooterNavItem to="/privacy">Privacy</FooterNavItem>
        <FooterNavItem
          href="https://twitter.com/personablyco"
          icon={TwitterIcon}
        >
          Twitter
        </FooterNavItem>
        <FooterNavItem
          href="https://linkedin.com/company/personably"
          icon={LinkedInIcon}
        >
          LinkedIn
        </FooterNavItem>
        <MadeIn size="small">
          Made in London{" "}
          <span role="img" aria-label="Great Britain">
            🇬🇧
          </span>
        </MadeIn>
      </FooterNavContainer>
    </FlexSection>
    <FillMargin right />
  </FooterContainer>
);

export default Footer;
